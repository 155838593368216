import moment from "moment";
import React, {useEffect, useMemo} from 'react';
import {useHistory, useParams} from "react-router-dom";
import ShowField from "v4/components/form/ShowField/ShowField";
import Loader from "v4/components/ui/Loader/Loader";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import EntityLoop from "v4/layouts/partials/EntityLoop/EntityLoop";
import 'v4/layouts/ShowEntityLayout/ShowEntityLayout.scss';
import {generateUrl} from "v4/services/api.service";

export default function ShowEntityLayout({entityId, entityRouteName, fetchFormParameters = {}, overrideData, onGetData, whenDeleteFile, children}) {
    const {t} = usePolTranslation();
    const [{data, isLoading, isError}, fetchForm] = useFetch();
    const [refetch, toggleRefetch] = useToggle();

    useEffect(() => {
        fetchForm({url: generateUrl(entityRouteName, {id: entityId, ...fetchFormParameters})});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityRouteName, entityId, refetch]);

    const {location, replace} = useHistory();
    const {prospectId} = useParams();
    useEffect(() => {
        if (location.state?.showHasToRefresh) {
            const {showHasToRefresh, ...newState} = location.state;
            replace({state: newState});
            let shouldRefresh = true;
            if (location.state?.dontRefreshProspectView) {
                shouldRefresh = entityId !== prospectId;
            }
            shouldRefresh && toggleRefetch();
        }
        if (location.state?.refreshProspectView) {
            const {refreshProspectView, ...newState} = location.state;
            replace({state: newState});
            if (entityId === prospectId) toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.showHasToRefresh, location.state?.dontRefreshProspectView, location.state?.refreshProspectView]);

    const inputs = useMemo(() => {
        return overrideData ?? data?.['hydra:member'] ?? null;
    }, [data, overrideData])

    const flatInputs = useMemo(() => {
        return inputs ? Object.values(inputs?.results ?? {}).reduce((acc, inputsGroup) => {
            acc.push(...inputsGroup.properties);
            return acc;
        }, []) : null;
    }, [inputs])

    useEffect(() => {
        onGetData && onGetData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className="show-layout">
            {isLoading
                ? <Loader className="show-entity-loader"/>
                : (
                    <>
                        {isError && <div className="form__error">Une erreur est survenue</div>}
                        {inputs && (
                            <>
                                <EntityLoop inputsGroups={inputs} groupClassName="show-group">
                                    {(input, {fullname}) => <ShowField {...input}
                                                                       fullname={fullname}
                                                                       allInputs={flatInputs}
                                                                       specificParams={{whenDeleteFile}}/>}
                                </EntityLoop>
                                {children}
                                <div className="show-layout__meta">
                                    {
                                        inputs.metadata?.createdAt && (
                                            <p>
                                                {t('createdAt_info')}
                                                <b>{moment(inputs.metadata?.createdAt).format(`DD/MM/YYYY [${t('at')}] HH:mm`)} </b>
                                                {inputs.metadata?.createdBy && (
                                                    <>
                                                        {t('by')} <b>{inputs.metadata?.createdBy}</b>
                                                    </>
                                                )}
                                            </p>
                                        )
                                    }
                                    {
                                        inputs.metadata?.updatedAt && (
                                            <p>
                                                {t('updatedAt_info')}
                                                <b>{moment(inputs.metadata?.updatedAt).format(`DD/MM/YYYY [${t('at')}] HH:mm`)} </b>
                                                {inputs.metadata?.updatedBy && (
                                                    <>
                                                        {t('by')} <b>{inputs.metadata?.updatedBy}</b>
                                                    </>
                                                )}
                                            </p>
                                        )
                                    }
                                </div>
                            </>
                        )}
                    </>
                )}
        </div>
    )
}
