import React, {useContext, useEffect, useState} from 'react';
import {usePolTranslation, withPolTranslation} from "v4/hooks/usePolTranslation";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {
    SECTION_FORM,
    SECTION_FORM_EDIT,
    SECTION_ITEM,
    SECTIONS
} from "v4/data/apiRoutes";
import SectionsListing from "views/Administration/pages/_partial/SectionsListing";
import {Redirect} from "react-router-dom";
import SectionsForm from "views/Administration/pages/_partial/SectionsForm";
import PopupInfoConfirm from "components/Popup/_popup_info_confirm";
import {setPopup} from "actions/content";
import {connect} from "react-redux";
import Loading from "components/Loading/Loading";
import {AdminContext} from "v4/contexts/AdminContext";

/**
 * Sections component with SectionsListing and SectionForm
 * @param match
 * @param setPopup
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
function Sections({match, setPopup}) {
    const [{data, isError}, hookFetchGet] = useFetch();
    const {asCustomer} = useContext(AdminContext)

    const [redirect, setRedirect] = useState(null);
    const [{isLoading}, fetchHookDelete] = useFetch();
    const {t} = usePolTranslation();

    const sectionId = match && match.params ? match.params.id : null;
    const COL_ID = "ID";
    const COL_CODE = "Code";
    const COL_PARENT = "Parent";
    const COL_LEADERS = "Gérants";
    const COL_MEMBERS = "Membres";
    const COL_IS_VISIBLE = "Visible ?";
    const items = {
        head: [COL_ID, COL_CODE, COL_PARENT, COL_LEADERS, COL_MEMBERS, COL_IS_VISIBLE],
        body: []
    };
    let sections = !isError && (data?.['hydra:member'] ?? data);

    useEffect(() => {
        if (asCustomer?.id) {
            hookFetchGet({
                url: generateUrl(SECTIONS, {id: asCustomer.id}),
            })
        }
    }, [asCustomer, hookFetchGet]);

    if (null !== sections && sections.length) {
        sections.map((section, key) => {
            let item = {};
            item.key = key;
            item.id = section.id;
            item[COL_ID] = section.id;
            item[COL_CODE] = section.code;
            item[COL_PARENT] = section.parent?.code ?? '';
            item[COL_LEADERS] = section.leaders ? section.leaders.map(u => u.fullname).join(', ') : '';
            item[COL_MEMBERS] = section.members ? section.members.map(u => u.fullname).join(', ') : '';
            item[COL_IS_VISIBLE] = t(true === section.isVisible ? 'yes' : 'no');
            items.body.push(item);

            return section;
        })
    }

    /**
     * Go to form for section
     * @param {string} id
     */
    const editSection = (id) => {
        setRedirect(
            <Redirect to={`/administration/sections/${id}`}/>
        )
    }

    /**
     * remove section
     * @param {string} id
     */
    const removeSection = (id) => {
        setPopup({
            isOpen: true,
            target: 'delete_item',
            content: (
                <PopupInfoConfirm
                    message={t('popup_validation_remove_section')}
                    onAccept={() => {
                        fetchHookDelete({
                            url: generateUrl(SECTION_ITEM,{id}),
                            config: {
                                method: 'DELETE'
                            }
                        });

                        setPopup({});
                    }}
                    onDecline={() => {
                        setPopup({})
                    }}
                />
            )
        });
    }

    return (
        <>
            {redirect}

            {null === sections || isLoading ?
                <Loading message={t("loading")} specificClass={"form-loader"}/>
                :
                <>
                    {sectionId ?
                        <SectionsForm
                            path={
                                '0' === sectionId
                                    ? generateUrl(SECTION_FORM, {customerId: asCustomer?.id})
                                    : generateUrl(SECTION_FORM_EDIT, {id: sectionId, customerId: asCustomer?.id})
                            }
                            goBack={() => editSection('')}
                            pathSubmit={'0' === sectionId ? generateUrl(SECTIONS) : generateUrl(SECTION_ITEM, {id: sectionId})}
                            submitMethod='POST'
                            sectionId={sectionId}
                            customerId={asCustomer?.id}
                        />
                        :
                        <SectionsListing headers={items.head} items={items.body} edit={editSection} remove={removeSection}/>
                    }
                </>
            }
        </>
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        setPopup: (popupInfo) => dispatch(setPopup(popupInfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Sections));
