import {captureException} from "@sentry/react";
import {useEffect, useState} from 'react';
import {debounce} from "v4/utils";
import {POSTAL_CODE_SERVICE} from "v4/data/externalApiRoutes";

export default function usePostalCodeAutocomplete(fieldValue) {
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        if (fieldValue?.length >= 2) {
            debounce(() =>
                getAutocomplete(fieldValue).then(data => {
                    setDataList(data);
                })
            );
        }
    }, [fieldValue]);

    return dataList;
}

async function getAutocomplete(postalCode) {
    try {
        const url = new URL(POSTAL_CODE_SERVICE);
        url.searchParams.set('code', postalCode);
        const response = await fetch(url.toString());
        const {cities} = await response.json();

        return cities ?? [];
    } catch (e) {
        captureException(e, {
            message: 'Error while fetching autocomplete for postalCode',
            extra: {
                postalCode
            }
        })
    }
}
