import * as Sentry from "@sentry/react";
import {captureException, setUser as setSentryUser} from "@sentry/react";
import {getLSItem, removeLSItem, setLSItem} from "v4/services/localStorage.service";
import {badCredentials, loadUser, setToken, setUser} from "../../actions/user";

let alternativeTranslationsCache = null;

function fetchHandler(res) {
    if (res.status >= 400 && res.status < 600) {
        return Promise.reject(res);
    }
    return res.json();
}

export default class UserService {
    /**
     * Set current user from cache
     * @param {Object} user
     */
    static setUser = (user = {}) => {
        setLSItem('user', user);
        Sentry.setUser({
            id: user?.id,
            username: user?.fullname,
            customer: user?.customer ? `${user.customer.name} (${user.customer.id})` : null,
            login: user.login,
            email: user?.email,
            roles: user.roles['hydra:member'],
            ip_address: "{{auto}}",
        })

        UserService.removeCache();
    };

    static removeCache = () => {
        alternativeTranslationsCache = null;
    }

    /**
     * Get current user from cache
     * @returns {Object}
     */
    static getUser = () => {
        const user = getLSItem('user');
        return Object.keys(user ?? {}).length ? user : null;
    };

    static getCustomerId = () => {
        const user = getLSItem('user')

        return user?.customer?.id
    }

    /**
     * Is it Liveo user who's logged ?
     * @returns {boolean}
     */
    static isLiveo = () => {
        const user = getLSItem('user')

        return user && user.liveoLogin && user.liveoLogin.length && user.customer && user.customer.isLiveo === true
    }

    /**
     * Current user has intranet ?
     * @returns {boolean}
     */
    static hasIntranet = () => {
        const user = getLSItem('user')

        return user?.customer?.hasIntranet ?? false;
    }

    /**
     * Remove current user from cache
     */
    static removeUser = () => {
        removeLSItem('user');
    };

    /**
     * Get all the custom tab of User from cache
     * @returns {(Object[]|array)}
     */
    static getTabs = () => {
        const user = getLSItem('user');
        if (user && user.tabs && user.tabs['hydra:member']) {
            return user.tabs['hydra:member'];
        }

        return [];
    };

    /**
     * Remove one custom tab of User from cache
     * @param {string} removedTabID
     *
     * @returns {(Object[]|array)}
     */
    static removeTab = (removedTabID) => {
        const tabs = UserService.getTabs();

        if (tabs) {
            const indexOfSearch = tabs.findIndex(search => search.id === removedTabID);
            if (indexOfSearch !== -1) {
                tabs.splice(indexOfSearch, 1)
            }

            return UserService.setTabs(tabs);
        }
    }

    /**
     * Set or add one custom tab of User from cache
     * @param {Object} tab
     * @param {string} tab.id
     *
     * @returns {(Object[]|array)}
     */
    static setTab = (tab) => {
        const user = getLSItem('user');
        const tabs = UserService.getTabs();

        if (Object.keys(tab).length && tab.customerId === user.customer.id) {
            let tabFound = tabs.findIndex(el => el.id === tab.id);
            if (-1 === tabFound) {
                tabs.push(tab);

                return UserService.setTabs(tabs);
            }
            tabs[tabFound] = tab;
        }

        return UserService.setTabs(tabs);
    }

    /**
     * Set custom tabs of User from cache
     * @param {Object[]} tabs
     *
     * @returns {Object[]}
     */
    static setTabs = (tabs) => {
        const user = getLSItem('user');
        if (!user.tabs) {
            user.tabs = [];
        }
        user.tabs['hydra:member'] = tabs;
        UserService.setUser(user);

        return user.tabs['hydra:member'];
    }

    /**
     * get slug of the tab with id in argument
     * @param {string} id
     *
     * @returns {string|null}
     */
    static getTabSlugFor = (id) => {
        const tabs = UserService.getTabs();
        const tab = tabs.find(el => el.id === id);

        return -1 === tab || !tab ? null : 'onglet/' + tab.slug;
    }

    static getAlternativeTranslations = () => {
        if (!alternativeTranslationsCache) {
            const user = getLSItem('user');
            alternativeTranslationsCache = user?.customer?.alternativeTranslations ? JSON.parse(user.customer.alternativeTranslations) : {};
        }

        return alternativeTranslationsCache;
    }

    static getSavedSearchs = () => {
        const user = getLSItem('user');
        if (user?.savedUserSearchs?.['hydra:member']) {
            return user.savedUserSearchs['hydra:member'];
        }
    };

    static removeSavedSearch = (removedSearchID) => {
        const user = getLSItem('user');

        if (user && user.savedUserSearchs && user.savedUserSearchs['hydra:member']) {
            const indexOfSearch = user.savedUserSearchs['hydra:member'].findIndex(search => search.id === removedSearchID);
            if (indexOfSearch !== -1) {
                user.savedUserSearchs['hydra:member'].splice(indexOfSearch, 1)
            }
            UserService.setUser(user);
            return user.savedUserSearchs['hydra:member']
        }
    }

    static setSavedSearch = (savedSearch) => {
        const user = getLSItem('user');

        if (Object.keys(savedSearch).length) {
            user.savedUserSearchs['hydra:member'].push(savedSearch)
        }
        UserService.setUser(user)
        return user.savedUserSearchs['hydra:member']
    }

    static setSavedRequest = (savedRequest, entityOrTabId) => {
        const user = getLSItem('user');
        let savedRequests = {};

        if (Object.keys(savedRequest).length) {
            try {
                savedRequests = JSON.parse(user.defaultSearchFilters);
                savedRequests[entityOrTabId] = savedRequest;
                user.defaultSearchFilters = JSON.stringify(savedRequests);
                UserService.setUser(user)
            } catch (e) {
                captureException(e, {
                    message: 'Error while parsing defaultSearchFilters when setting savedRequest in UserService',
                    extra: {
                        user: user,
                        savedRequest: savedRequest,
                        entityOrTabId: entityOrTabId,
                    }
                });
            }
        }
    }

    static setTokens = (token = false, refresh_token = false, refresh = false) => {
        setLSItem('token', token);
        setLSItem('refresh_token', refresh_token);
        if (refresh) {
            document.location.reload(false);
        }

        UserService.removeCache();
    };

    static getRoles = () => {
        const user = getLSItem('user');

        if (user && user.roles && user.roles['hydra:member']) {
            return user.roles['hydra:member'];
        }

        return [];
    };

    static getAccessRight = () => {
        const user = getLSItem('user');

        if (user && user?.accessRights && user.accessRights['hydra:member']) {
            return user.accessRights['hydra:member'];
        }

        return [];
    }

    /**
     * Check that the current user has at least one of the given roles, or that they are a superAdmin.
     *
     * @param userRoles
     * @param checkSuperAdmin
     *
     * @returns {boolean}
     */
    static controlAccess = (userRoles = ['ROLE_USER'], checkSuperAdmin = true) => {
        const roles = UserService.getRoles();
        if (checkSuperAdmin && roles.indexOf(ROLE_SUPER_ADMIN) !== -1) {
            return true;
        }
        return userRoles.some(userRole => roles.includes(userRole));
    };

    static submitFormSearchOnLoading = (orderEntity) => {
        const ROLES = {
            Quote: 'MY_QUOTE_BY_DEFAULT',
            Task: 'MY_TASK_BY_DEFAULT',
            Prospect: 'MY_PROSPECT_BY_DEFAULT',
            Contact: 'MY_CONTACT_BY_DEFAULT',
        }
        return UserService.controlAccess([ROLES[orderEntity]], false)
    }

    static getTokens = () => {
        const token = getLSItem('token', 'string');
        if (token) {
            const user = getLSItem('user');
            if (user) {
                setSentryUser({
                    id: user?.id,
                    username: user?.fullname,
                    customer: user?.customer ? `${user.customer.name} (${user.customer.id})` : null,
                    login: user.login,
                    email: user?.email,
                    roles: user.roles['hydra:member'],
                    ip_address: "{{auto}}",
                });
            }

            return {
                token: token,
                refresh_token: getLSItem('refresh_token', 'string')
            }
        }

        return false;
    };

    static removeTokens = () => {
        removeLSItem('token');
        removeLSItem('refresh_token');

        UserService.removeCache();
    };

    static getGlobalSearch = () => {
        if (getLSItem('global_search')) {
            return getLSItem('global_search');
        }
        return null;
    };

    static setGlobalSearch = (search) => {
        setLSItem('global_search', search);
    };

    static getVisibleInfos = (entity, type, returnType = 'list', admin = false, group = false) => {
        const user = getLSItem('user');
        let visibleInfos = [];
        let ordersEntity;
        type = type === 'view' ? 'form' : type;

        if (user) {
            let ordersUser = JSON.parse(user).orders['hydra:member'];

            ordersEntity = ordersUser.filter(item => item.entity === entity);
            if (ordersEntity.length) {
                let orders = ordersEntity[0].viewOrderInfos;

                if (orders.length) {
                    orders = orders.filter(item => {
                        return !group ? item.viewOrderType === type && item.groupe === true : item.viewOrderType === type && item.groupe === true && item.type === group
                    });

                    switch (type) {
                        case 'list':
                            visibleInfos = returnType === 'list' && orders.length ? orders.shift().viewOrderInfos : ordersEntity;
                            break;
                        case 'view':
                            visibleInfos = orders;
                            break;
                        case 'form':
                            visibleInfos = orders;
                            break;
                        default:
                            visibleInfos = orders;
                            break;
                    }
                }
            }
        }

        const parentEntity = ordersEntity[0] ? {
            ...ordersEntity[0]
        } : {};

        return admin ? {
            parentEntity: parentEntity,
            visibleInfos: visibleInfos,
        } : visibleInfos;
    };

    static setOrder = (entity, type, changeOrders = {}, group = false) => {
        const user = JSON.parse(getLSItem('user'));

        if (user) {
            let ordersUser = user.orders['hydra:member'];
            let ordersEntity = ordersUser.find(item => item.entity === entity);

            if (ordersEntity && ordersEntity.viewOrderInfos) {
                let orders = ordersEntity.viewOrderInfos;
                if (orders.length) {
                    const oldOrders = orders.filter(item => {
                        return group ? item.viewOrderType === type && item.groupe && item.type === group : item.viewOrderType === type && item.groupe
                    });

                    oldOrders.forEach(oldOrder => {
                        if (!changeOrders.filter(changeOrder => changeOrder.id === oldOrder.id).length) {
                            const spliceIndex = orders.findIndex(order => oldOrder.id === order.id);
                            orders.splice(spliceIndex, 1);
                        }
                    });

                    changeOrders.forEach(changeOrder => {
                        const orderItemId = orders.findIndex(order => order.id === changeOrder.id);
                        if (orderItemId >= 0) {
                            orders[orderItemId] = changeOrder;
                        } else if (changeOrder.id !== 'empty') {
                            orders.push(changeOrder);
                        }
                    });
                }

                ordersEntity.viewOrderInfos = orders;
            }

            UserService.setUser(user);
        }
    };

    static isLastVisit = (key) => {
        const listlastVisits = ['contacts'];

        if (listlastVisits.length) {
            const keyExist = listlastVisits.filter(item => item === key);

            if (keyExist.length) {
                return true;
            }
        }

        return false;
    };

    static logout = () => {
        UserService.removeTokens();
        UserService.removeCache();
        UserService.removeUser();
    }

    static isLogged = () => {
        return !!UserService.getTokens();
    }

    static getLocale = () => {
        const locale = getLSItem('i18nextLng', 'string');
        if (locale.length > 0) return locale;

        setLSItem('i18nextLng', 'fr-FR');
        return 'fr-FR';
    }
};

function getTime(dispatch, error = false, data = []) {
    if (!error && data.user) {
        dispatch(setUser(data.user));
    }

    setTimeout(function () {
        dispatch(loadUser(false));

        if (!error) {
            if (data.token && data.refreshToken) {
                dispatch(setToken(data.token, data.refreshToken));
            } else {
                dispatch(badCredentials({
                    status: 500,
                    data: 'No datas return'
                }));
            }
        } else {
            dispatch(badCredentials(error));
        }
    }, 500)
}

export function LoginService(info, setToken) {
    return function (dispatch) {
        dispatch(loadUser(true));

        return fetch(process.env.REACT_APP_HOST_API + '/api/login', {
            method: 'POST',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(info),
        }).then(
            fetchHandler,
            error => {
                getTime(dispatch, {
                    status: 500,
                    data: error
                });
            }
        ).then(
            data => {
                if (data) {
                    setToken(data.token, data.refreshToken);
                    dispatch(InfoService(data))
                }
            },
            error => {
                getTime(dispatch, error);
            }
        );
    };
}

export function InfoService(loginData) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_HOST_API + '/api/user', {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + loginData.token
            },
        }).then(
            fetchHandler,
            error => {
                getTime(dispatch, {
                    status: 500,
                    data: error
                });
            }
        ).then(
            data => {
                getTime(dispatch, false, {user: data, ...loginData});
            },
        );
    };
}

export const ROLE_SUPER_ADMIN = 'ROLE_SUPERADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_MODULE_PRODUCT_CUSTOMIZE = 'ROLE_MODULE_PRODUCT_CUSTOMIZE';
